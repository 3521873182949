import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addMachineRequest } from '../../store/actions/machine.action';
import axios from 'axios';
import {
    Box,
    Button,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Grid,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    Divider
} from '@mui/material';
import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';

const AddMachineRequest = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state;
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;

    const today = new Date();
    const todayFormattedDate = today.toISOString().split('T')[0];
    const next90Days = new Date();
    next90Days.setDate(today.getDate() + 90);
    const next90DaysFormatted = next90Days.toISOString().split('T')[0];

    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        type: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [machineSlots, setMachineSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [dateError, setDateError] = useState(''); // To store date validation error
    const [timeError, setTimeError] = useState(''); // To store time validation error

    const apiUrl = process.env.REACT_APP_SCHEDULER_API_URL;

    useEffect(() => {
        const fetchMachineSlots = async () => {
            setLoadingSlots(true);
            try {
                const response = await axios.get(
                    `${apiUrl}/api/v1/custom-machine-slot/get-machine-custom-slots/${data?.machineId}`
                );
                if (response.status === 200) {
                    setMachineSlots(response.data.slotDetails);
                }
            } catch (error) {
                console.error('Error fetching machine slots:', error);
            }
            setLoadingSlots(false);
        };
        fetchMachineSlots();
    }, [data?.machineId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Reset error messages when input changes
        if (name === 'startDate' || name === 'endDate') {
            setDateError('');
        }
        if (name === 'startTime' || name === 'endTime') {
            setTimeError('');
        }
    };

    const handleLeaveTypeChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            type: value,
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Date validation: End date must not be before start date
        if (formData.endDate && formData.startDate && formData.endDate < formData.startDate) {
            setDateError('End date cannot be earlier than start date.');
            return;
        }

        // Time validation: If dates are equal, end time must be greater than start time
        if (formData.startDate && formData.startTime && formData.endTime && formData.endTime <= formData.startTime) {
            setTimeError('End time must be greater than start time.');
            return;
        }

        setIsSubmitting(true);

        const reqBody = formData.type === 'REPAIR'
            ? {
                machineId: data?.machineId,
                startDate: formData.startDate,
                endDate: formData.endDate,
                type: formData.type
            }
            : {
                machineId: data?.machineId,
                startDate: formData.startDate,
                type: formData.type,
                startTime: formData.startTime + ":00",
                endTime: formData.endTime + ":00",
            };

        const res = await addMachineRequest(reqBody);
        if (res?.status === 200) {
            alert(res.data.status?.message);
            navigate(`/machinelist`, { state: data });
            // navigate(`/view-machine/${data?.machineId}`, { state: data });
        }
        setIsSubmitting(false);
    };

    return (
        <Container
            sx={{
                margin: { xs: '10px', sm: '10px' },
                padding: '10px',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            }}
        >
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                <Typography variant="h5" sx={{ color: '#0077b6', fontWeight: 'bold', padding: '10px' }}>
                    Add Machine Unavailability/Leave Request
                </Typography>
            </Box>
            <Divider style={{ marginBottom: '40px', backgroundColor: '#0077b6' }} />
            
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    maxWidth: 600,
                    margin: '0 auto',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Machine Name"
                            value={data?.machineName}
                            InputProps={{
                                readOnly: true
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="leave-type-label">Leave Type</InputLabel>
                            <Select
                                labelId="leave-type-label"
                                name="type"
                                value={formData.type}
                                onChange={handleLeaveTypeChange}
                                required
                                label="Leave Type"
                            >
                                <MenuItem value="" sx={{color: 'red'}}>CLEAR</MenuItem>
                                <MenuItem value="REPAIR">REPAIR</MenuItem>
                                <MenuItem value="UNAVAILABLE">UNAVAILABLE</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {(formData.type === "REPAIR" || formData.type === "UNAVAILABLE") && <Grid item xs={12} sm={6}>
                        <TextField
                            label="Start Date"
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ min: todayFormattedDate, max: next90DaysFormatted }}
                            fullWidth
                            required
                        />
                    </Grid>}

                    {formData.type === "REPAIR" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                name="endDate"
                                value={formData.endDate}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ min: formData.startDate, max: next90DaysFormatted }}
                                fullWidth
                                required
                                error={formData.endDate < formData.startDate ? 'End date cannot be earlier than start date.': null} // Show error state if there's a date validation error
                                helperText={dateError} // Show the error message
                            />
                        </Grid>
                    )}
                </Grid>

                {formData.type === "UNAVAILABLE" && (
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Start Time"
                                type="time"
                                name="startTime"
                                value={formData.startTime}
                                onChange={handleChange}
                                inputProps={{
                                    step: 1800,
                                    min: '07:00',
                                    max: '22:00'
                                }}
                                fullWidth
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Time"
                                type="time"
                                name="endTime"
                                value={formData.endTime}
                                onChange={handleChange}
                                inputProps={{
                                    step: 1800,
                                    min: '07:00',
                                    max: '22:00'
                                }}
                                fullWidth
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={formData.startTime >= formData.endTime ? 'End time must be greater than start time.': null}
                                helperText={timeError} // Show the error message
                            />
                        </Grid>
                    </Grid>
                )}

                {hasAdminAndSuperAdminAccess(role) && <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{
                        mt: 4,
                        display: 'block',
                        mx: 'auto',
                        width: '60%',
                        background: isSubmitting ? 'gray' : '#007bff',
                        cursor: isSubmitting ? 'not-allowed' : 'pointer',
                    }}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                </Button>}
            </Box>

            <Box mt={5}>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: '600' }}
                >
                    Previous Actions
                </Typography>
                {loadingSlots ? (
                    <CircularProgress />
                ) : (
                    <TableContainer component={Paper} sx={{ width: '80%', mx: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Machine Id</TableCell>
                                    <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Start Time</TableCell>
                                    <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>End Time</TableCell>
                                    <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Type</TableCell>
                                    <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>Start Date</TableCell>
                                    <TableCell sx={{ bgcolor: 'linear-gradient(to right, #bdc3c7, #2c3e50)', fontWeight: 'bold', color: '#000' }}>End Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {machineSlots.map((slot, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{slot.machineId}</TableCell>
                                        <TableCell>{slot.startTime || '-'}</TableCell>
                                        <TableCell>{slot.endTime || '-'}</TableCell>
                                        <TableCell>{slot.type}</TableCell>
                                        <TableCell>{slot.startDate.split('T')[0]}</TableCell>
                                        <TableCell>{slot.endDate.split('T')[0]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Container>
    );
};

export default AddMachineRequest;
