import React, { useEffect } from 'react'
import TableComponent from '../../components/common/TableComponent/TableComponent'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTherapist, fetchTherapist } from '../../store/actions/therapist.action';
import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TherapistList = () => {
  const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let therapistList = useSelector(state => state?.therapist?.therapistList?.therapists)
  let isTherapistListLoading = useSelector(state => state?.therapist?.isTherapistListLoading)

  const handleViewDetails=(data)=>{
    if(data){
      navigate(`/view-therapist/${data?.therapistId}`, {state: data})
    }

  }
  
  useEffect(() => {
    dispatch(fetchTherapist())
  }, [dispatch])

  const handleDelete = async (machineId) => {
    try {
      const result = await deleteTherapist(machineId);
      toast.dismiss();
      dispatch(fetchTherapist())
    } catch (error) {
      console.error('Error deleting machine:', error);
    }
  };


  const handleUpdate = (data) => {
    if (data) {
      toast(
        <div className="custom-toast-btn">
          <b>Are you sure you want to delete this therapist?</b>
          <div className="toast-delete-btn">
            <button className="yes-btn-confirm" onClick={() =>handleDelete(data?.therapistId)}>Yes</button>
            <button className="no-btn-confirm" onClick={() => toast.dismiss()}>No</button>
          </div>
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          position: "top-center",
        }
      );
    }
    return true;
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
        <h3 style={{ margin: '0 auto' }}>All  Therapist</h3>
        {hasAdminAndSuperAdminAccess(role)&&<Button variant="contained" color="primary" onClick={() => navigate("/add-therapist")}>Add Therapist</Button>}
      </div>
      {/* <TableComponent data={therapistList}
        hiddenFields={["centerId", "createdAt", "deletedAt", "extra", "id", "lat", "location", "long", "products", "saturdayAvailability", "sundayAvailability", "thursdayAvailability", "tuesdayAvailability", "updatedAt", "wednesdayAvailability", "fridayAvailability", "mondayAvailability"]}
        viewButton={hasAdminAndSuperAdminAccess(role)&&<EditIcon />}
        viewDetails={handleEdit}
      /> */}
      {isTherapistListLoading?
      (<LoaderComponent/>)
      :(
      <div>
      <TableComponent 
        data={therapistList}
        showUpdateButton={"Delete"}
        updateDetails={handleUpdate}
        viewButton={'view'}
        viewDetails={handleViewDetails}
      />
      <ToastContainer/>
      </div>
      )}
    </div>
  )
}

export default TherapistList
