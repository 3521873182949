import React, { useEffect, useState } from 'react'
import TableComponent from '../../components/common/TableComponent/TableComponent'
import { deleteMachineProduct, fetchMachineServiceAbleProducts } from '../../store/actions/machine.action'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import ProductAddModal from './ProductAddModal'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastStyle.css';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent'

const ViewMachine = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const data = location?.state;
  let machineServiceableProduct = useSelector(state => state?.machine?.machineServiceableProducts?.products)
  let isLoadingMachineServiceableProducts = useSelector(state => state?.machine?.isLoadingMachineServiceableProducts)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const products = [
    { id: 1, name: 'Product A' },
    { id: 2, name: 'Product B' },
    { id: 3, name: 'Product C' },
    { id: 4, name: 'Product A' },
    { id: 4, name: 'Product B' },
    { id: 5, name: 'Product C' },
    { id: 6, name: 'Product A' },
    { id: 7, name: 'Product B' },
    { id: 8, name: 'Product C' }
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (machineId) => {
    try {
      const result = await deleteMachineProduct(machineId);
      toast.dismiss();
      dispatch(fetchMachineServiceAbleProducts(params?.machineId))
    } catch (error) {
      console.error('Error deleting machine:', error);
    }
  };

  const handleUpdate = (data) => {
    if (data) {
      toast(
        <div className="custom-toast-btn">
          <b>Are you sure you want to delete this machine?</b>
          <div className="toast-delete-btn">
            <button className="yes-btn-confirm" onClick={() => handleDelete(data?.id)}>Yes</button>
            <button className="no-btn-confirm" onClick={() => toast.dismiss()}>No</button>
          </div>
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          position: "top-center",
        }
      );
    }
    return true;
  };

  const handleSubmit = (selectedProductIds) => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchMachineServiceAbleProducts(params?.machineId))
  }, [params])



  return (
    <div>
      <h3>Machine's Serviceable Services</h3>
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginRight: '30px', gap: '10px' }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#4CAF50', color: 'white' }}
          onClick={() => navigate("/add-machine-request", { state: data })}
        >
          Add Machine Request
        </Button>
      </div>

      {isLoadingMachineServiceableProducts ? (
        <LoaderComponent />
      ) : (<div>
        <TableComponent
          hiddenFields={'id'}
          data={machineServiceableProduct}
          showUpdateButton={"Delete"}
          updateDetails={handleUpdate}
        />
        <ToastContainer />
      </div>)}
      <ProductAddModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        products={products}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default ViewMachine