import React, { useEffect, useState } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, Button, Typography, Box, Container, TextField 
} from '@mui/material';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

function SameDayBookings() {
    const [sameDayBookings, setSameDayBookings] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));

    // Fetch data
    const fetchData = () => {
        // Validate the date range
        if (new Date(startDate) > new Date(endDate)) {
            toast.error('Start date cannot be greater than end date.');
            return;
        }

        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/same-day-bookings?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            }
        })
            .then(res => setSameDayBookings(res.data.sameDayBookings))
            .catch(error => {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data.');
            });
    };

    // Fetch data on mount
    useEffect(() => {
        fetchData();
    }, []);

    // Handle date change
    const handleDateChange = (event) => {
        if (event.target.name === 'startDate') {
            setStartDate(event.target.value);
        } else {
            setEndDate(event.target.value);
        }
    };

    // Handle date filter submission
    const handleDateFilter = () => {
        fetchData();
    };

    return (
        <Container>
            <ToastContainer /> {/* Toast container for notifications */}
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Same Day Bookings
                </Typography>
                
                {/* Date Filter */}
                <Box display="flex" justifyContent="space-between" my={3}>
                    <TextField
                        label="Start Date"
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={handleDateChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={handleDateChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDateFilter}
                    >
                        Filter
                    </Button>
                </Box>

                {/* Bookings Table */}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>
                                <TableCell>Session Schedule IDs</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Appointment Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sameDayBookings.map(booking => (
                                <TableRow key={booking.orderId}>
                                    <TableCell>{booking.userID}</TableCell>
                                    <TableCell>
                                        {booking.sessionScheduleIds.split(',').map(id => (
                                            <Button 
                                                key={id} 
                                                color="primary" 
                                                onClick={() => window.open(`/booking-details/${id}`, '_blank')}
                                            >
                                                {id}
                                            </Button>
                                        ))}
                                    </TableCell>
                                    <TableCell>{booking.userName || 'N/A'}</TableCell>
                                    <TableCell>{booking.phoneNumber}</TableCell>
                                    <TableCell>{booking.appointmentAt}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
}

export default SameDayBookings;
