import React, { useEffect } from 'react'
import TableComponent from '../../components/common/TableComponent/TableComponent'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMachine, fetchMachine } from '../../store/actions/machine.action';
import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastStyle.css';
import MachineTable from './MachineTable';
const MachineListing = () => {
  const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let machineList = useSelector(state => state?.machine?.machineList?.machines || [])
  let isLoadingMachineList = useSelector(state => state?.machine?.isLoadingMachineList)

  const handleViewDetails = (data) => {
    if (data) {
      navigate(`/add-machine-request`, { state: data });
      // navigate(`/view-machine/${data?.machineId}`, { state: data });
    }
  }
  const handleDelete = async (machineId) => {
    try {
      const result = await deleteMachine(machineId);
      toast.dismiss();
      dispatch(fetchMachine())
    } catch (error) {
      console.error('Error deleting machine:', error);
    }
  };
  

  const handleUpdate = (data) => {
    if (data) {
      toast(
        <div className="custom-toast-btn">
          <b>Are you sure you want to delete this machine?</b>
          <div className="toast-delete-btn">
            <button className="yes-btn-confirm" onClick={() =>handleDelete(data?.machineId)}>Yes</button>
            <button className="no-btn-confirm" onClick={() => toast.dismiss()}>No</button>
          </div>
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          position: "top-center",
        }
      );
    }
    return true;
  };

  useEffect(() => {
    dispatch(fetchMachine())
  }, [dispatch])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
        <h2 style={{ margin: '0 auto' }}>All Machines</h2>
        {hasAdminAndSuperAdminAccess(role) && <Button variant="contained" color="primary" onClick={() => navigate("/add-machine")}>Add Machine</Button>}
      </div>
      {/* <TableComponent data={machineList}
        hiddenFields={["centerId", "createdAt", "deletedAt", "extra", "id", "lat", "location", "long", "products", "saturdayAvailability", "sundayAvailability", "thursdayAvailability", "tuesdayAvailability", "updatedAt", "wednesdayAvailability", "fridayAvailability", "mondayAvailability"]}
        viewButton={hasAdminAndSuperAdminAccess(role)&&<EditIcon />}
        viewDetails={handleEdit}
      /> */}
      {isLoadingMachineList ? (
        <LoaderComponent />
      ) : (
        <div>
          {/* <TableComponent
            data={machineList}
            viewButton={'Request'}
            viewDetails={handleViewDetails}
            showUpdateButton={"Delete"}
            updateDetails={handleUpdate}
          /> */}
          <MachineTable 
            machinesList={machineList}
            requestDetail={handleViewDetails}
            handleDelete={handleUpdate}
          />
          <ToastContainer />
        </div>
      )}
    </div>
  )
}

export default MachineListing