module.exports = {
    clientLocation: {
        bangalore: {
            lat: "12.9750417",
            long: "77.5965348",
            city: "Bangalore"
        },
        // 12.960924195675851, 77.63283205598005
        chandigarh: {
            lat: "30.7015368",
            long: "76.8217473",
            city: "Chandigarh"
        },
        //30.661625228491598, 76.73081876702945
        kotla: {
            lat: "28.5533505",
            long: "77.19997029999999",
            city: "Delhi"
        },
        //28.5692643737413, 77.21053376043986
        pitampura: {
            lat: "28.6853138",
            long: "77.09164109999999",
            city: "Delhi"
        },
        //28.69959493777774, 77.14707340816867
        mumbai: {
            lat: "19.0220859",
            long: "72.862578",
            city: "Mumbai"
        },
        //19.051773881626016, 72.91088508459546
        gurgaon: {
            lat: "28.4020591",
            long: "77.03971829999999",
            city: "Gurgaon"
        },
        //28.459646267622265, 77.09597718271188
        pune: {
            lat: "18.4294165",
            long: "73.97882659999999",
            city: "Pune"
        },
        //18.53710526654156, 73.90847218432926
        hyderabad: {
            lat: "17.4832729",
            long: "78.3266606",
            city: "Hyderabad"
        },
        //17.415540691738208, 78.43607508539056
        ahmedabad: {
            lat: "23.1035218",
            long: "72.5443293",
            city: "Ahmedabad"
        },
        //23.050859281398218, 72.5634287100866
        indore: {
            lat: "22.6672946",
            long: "75.888397",
            city: "Indore"
        },
        //23.022421659413066, 72.57161825373441
        ludhiana: {
            lat: "30.884829",
            long: "75.7902247",
            city: "Ludhiana"
        },
        //30.931084428650017, 75.8705047204878
        noida: {
            lat: "28.5808947",
            long: "77.4393211",
            city: "Noida"
        }
        //28.59924867959897, 77.34072299775796

    },
}